import { Injectable } from '@angular/core';
import { OutsideTrainingEnum } from '@enums/OutsideTrainingEnum';
import { AdminService } from './admin.service';
import { OutsideTraining } from '@models/outside_training.model';
import { DB_CONFIG } from '@app/app.firebase.config';
import { AuthenticationService } from './authentication.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class OutsideTrainingService {

  outside_modal;

  categorySettings = [
    {
      id: OutsideTrainingEnum.Mental,
      icon: 'extension-puzzle',
      iconColor: '#5b9bd5',
      background: '#bdd7ee',
    },
    {
      id: OutsideTrainingEnum.Social,
      icon: 'chatbubbles',
      iconColor: '#ffc000',
      background: '#ffe699',
    },
    {
      id: OutsideTrainingEnum.Stress,
      icon: 'rose',
      iconColor: '#ed7d31',
      background: '#f8cbad',
    },
    {
      id: OutsideTrainingEnum.Physical,
      icon: 'bicycle',
      iconColor: '#44546a',
      background: '#adb9ca',
    }
  ];
  
  constructor(
    private adminService: AdminService,
    private authService: AuthenticationService
  ) { 
    this.getUser();
  }

  user;
  getUser() {
    this.authService.userSubject.subscribe(user => {
      this.user = user;
    })
  }

  saveActivities(activities, bonus){
    const entry = new OutsideTraining(activities, bonus, this.user.id);
    this.adminService.saveEntryById(entry, DB_CONFIG.outside_training_endpoint);
  }

  async getActivities(userId?){
    return new Promise<OutsideTraining[]>((res,rej) => {
      if(!userId){
        userId = this.user.id;
      }
      // Determine the number of journal entries from the past week (Sunday to Sunday)
      const startOfWeek = moment().startOf('week').toDate().toJSON(); // Adjust this if your week starts on a different day
      const endOfWeek = moment().endOf('week').toDate().toJSON();
      
      this.adminService.getEntriesByDate(DB_CONFIG.outside_training_endpoint, startOfWeek, endOfWeek, 'user_id', this.user.id).subscribe(activities => {
        res(activities);
      })
    })
  }

  async getActivitiesWDate(startDate, EndDate, userId?){
    return new Promise<OutsideTraining[]>((res,rej) => {
      if(!userId){
        userId = this.user.id;
      }
      const startOfWeek = moment(startDate).toJSON();
      const endOfWeek = moment(EndDate).toJSON();
      
      this.adminService.getEntriesByDate(DB_CONFIG.outside_training_endpoint, startOfWeek, endOfWeek, 'user_id', this.user.id).subscribe(activities => {
        res(activities);
      })
    })
  }
}
