import { GameDifficultyEnum } from '@enums/DifficultyEnum';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';
import { Config } from '@models/config.model';

export const TEST_MODE = true;

export const gameConfig = [
  {
    gameId: 'gSgsIQkQuIlnkiJKSauo',
    inslimit: 8,
    practlimit: 10,
    quizlimit: 20,
    combination: 4,
    questionTime: 3,
    fixationPoint: true
  },
  {
    gameId: 'dknSLlDHDb0QemzGIEf8',
    inslimit: 8,
    practlimit: 2,
    quizlimit: 7,
    combination: 2,
    questionTime: 0,
    fixationPoint: false
  },
  {
    gameId: 'GvUFkD4qWS3JFVorWtYb',
    inslimit: 8,
    practlimit: 5,
    quizlimit: 50,
    combination: 4,
    questionTime: 0,
    fixationPoint: false
  }
];
export const states = [
  {
    name: 'Alabama',
    abbreviation: 'AL'
  },
  {
    name: 'Alaska',
    abbreviation: 'AK'
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS'
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ'
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR'
  },
  {
    name: 'California',
    abbreviation: 'CA'
  },
  {
    name: 'Colorado',
    abbreviation: 'CO'
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT'
  },
  {
    name: 'Delaware',
    abbreviation: 'DE'
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC'
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM'
  },
  {
    name: 'Florida',
    abbreviation: 'FL'
  },
  {
    name: 'Georgia',
    abbreviation: 'GA'
  },
  {
    name: 'Guam',
    abbreviation: 'GU'
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI'
  },
  {
    name: 'Idaho',
    abbreviation: 'ID'
  },
  {
    name: 'Illinois',
    abbreviation: 'IL'
  },
  {
    name: 'Indiana',
    abbreviation: 'IN'
  },
  {
    name: 'Iowa',
    abbreviation: 'IA'
  },
  {
    name: 'Kansas',
    abbreviation: 'KS'
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY'
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH'
  },
  {
    name: 'Maryland',
    abbreviation: 'MD'
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA'
  },
  {
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN'
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS'
  },
  {
    name: 'Missouri',
    abbreviation: 'MO'
  },
  {
    name: 'Montana',
    abbreviation: 'MT'
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE'
  },
  {
    name: 'Nevada',
    abbreviation: 'NV'
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH'
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ'
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM'
  },
  {
    name: 'New York',
    abbreviation: 'NY'
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC'
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND'
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP'
  },
  {
    name: 'Ohio',
    abbreviation: 'OH'
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK'
  },
  {
    name: 'Oregon',
    abbreviation: 'OR'
  },
  {
    name: 'Palau',
    abbreviation: 'PW'
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA'
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR'
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI'
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC'
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD'
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN'
  },
  {
    name: 'Texas',
    abbreviation: 'TX'
  },
  {
    name: 'Utah',
    abbreviation: 'UT'
  },
  {
    name: 'Vermont',
    abbreviation: 'VT'
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI'
  },
  {
    name: 'Virginia',
    abbreviation: 'VA'
  },
  {
    name: 'Washington',
    abbreviation: 'WA'
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV'
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI'
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY'
  }
];

export const yahootieInstructions = [
  {
    instruction: `<p>Let's talk about what we're going to do.</p> 
      <br>
      <p>This training is not just about physical exercise, you will also be asked to answer some questions.</p>`
  },
  {
    instruction: `<p>When you see a question, answer it as quickly as possible.</p>
      <p>You will get 1 point for each question that you answer correctly.</p>`
  },
  {
    instruction: `<p> If you notice that you have seen two of the same types of question in a row select the "Yahootie" button at the bottom of the screen.</p>
      <br>
      <p>A correct "Yahootie" is worth 3 points.</p>`
  },
  {
    instruction: `<p>For example, if you see two math questions in a row, you should select the "Yahootie" button.</p>`
  },
  {
    instruction: `<p>Have fun!</p>
      <br>
      <p>Remember:</p>
      <br>
      <p>Correct answers are worth <strong>1 point.</strong></p> 
      <p>Correct Yahooties are worth <strong>3 points.</strong></p>`
  }
];

export const roundTimer = {
  stretch : 30,
  exercise: 30,
  question: 60
};

export const yahootiePoint = 3;
export const questionLimit = 20;

export let brainGameDefaultConfig = {
  difficulty: '' as GameDifficultyEnum,
  game: '' as PuzzleGameEnum,
  name: 'testDefault',
  duration_of_round: 120,
  point: 3,
  number_of_rounds: 5,
  number_of_questions: 4,
} as Config;

export let APPS = [
{
  color: '#ca493f',
  description: '',
  imageUrl: 'https://firebasestorage.googleapis.com/v0/b/brain-evolved.appspot.com/o/Logo%2Fbe-brain-assess.png?alt=media&token=37e20bca-cf08-486b-a0e9-b3d4fb9b348a',
  name: 'Assessment',
  navTo: '',
},
{
  color: '#379370',
  description: 'Exercise your mind by managing time, strategy, and knowledge.',
  imageUrl: 'https://firebasestorage.googleapis.com/v0/b/brain-evolved.appspot.com/o/Logo%2Fbrain-logo.png?alt=media&token=f0456494-faf4-4cdb-8343-3ef6d68977fd',
  name: 'Brain Game',
  navTo: 'braingame/game-select',
},
{
  color: '#3aa2b3',
  description: 'Get brain smart and support from others.',
  imageUrl: 'https://firebasestorage.googleapis.com/v0/b/brain-evolved.appspot.com/o/Logo%2Fbe-brain-socialize.png?alt=media&token=457ceb5e-c47b-453b-b352-8b16066b689f',
  name: 'Clubhouse',
  navTo: 'clubhouse/meeting/select',
},
{
  color: '#35868c',
  description: 'Say goodbye to stress',
  imageUrl: 'https://firebasestorage.googleapis.com/v0/b/brain-evolved.appspot.com/o/Logo%2FhpLogo.png?alt=media&token=5daf14ec-632e-4678-8d83-75fb7066865c',
  name: 'Happy Place',
  navTo: 'happy-place/home',
},
{
  color: '#32342f',
  description: 'Break a physical and mental sweat.',
  imageUrl: 'https://firebasestorage.googleapis.com/v0/b/brain-evolved.appspot.com/o/Logo%2Fyahootie-logo.png?alt=media&token=db50d00f-8d3e-4a7e-9493-ef9fbe1fb698',
  name: 'Yahootie',
  navTo: 'yahootie/game-configuration',
},
]

if(TEST_MODE){
  brainGameDefaultConfig.number_of_rounds = 2;
}


export const Mantras = [
  'I create my own path and walk it with joy',
  'I create my own path and walk it with joy',
  'I create my own path and walk it with joy',
  'I create my own path and walk it with joy',
  'I create my own path and walk it with joy',
]