import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController, AlertController, ToastController, LoadingController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavigationEnum } from './enums/NavigationEnum';
import * as _ from 'underscore';

@Injectable()
export class Utilities {
  private SECONDS = 'seconds';
  modalDismiss = new EventEmitter();

  constructor(
    public navCtrl?: NavController,
    public toastController?: ToastController,
    public modalController?: ModalController,
    public alertController?: AlertController,
    public loadingCtrl?: LoadingController
  ) {}

  // public params: any;
  private modal: any;

  // should be moved to some confirmation service or something
  public alertOk: EventEmitter<any> = new EventEmitter();
  public singleAlertOk: EventEmitter<any> = new EventEmitter();
  private loadingConfig: any;

  // these are here in case needed for account creation later
  // public phonePattern = '^((\\+91-?)|0)?[0-9]{10}$';
  // public emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  

  /**
   * takes what is returned from snapshot
   * get the data and the id
   * then combines them
   * @param actions data to manipulate
   */
  static mapActions(actions: any) {
    return actions.map((a: any) => {
      // Get document data
      const data = a.payload.doc.data() as any;

      // Get document id
      const id = a.payload.doc.id;

      // Use spread operator to add the id to the document data
      return { id, ...data };
    });
  }

  /** map the data to get only what we want */
  static getMappedData(mappedData: any) {
    return mappedData.map((a: any) => {
      const data = a;
      return data;
    });
  }

  /** display form validation error messages */
  static triggerFormValidation(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      form.get(key).markAsDirty();
      form.get(key).markAsTouched();
      form.get(key).updateValueAndValidity();
    });
  }

  /**
   * navigates to the specified path
   * @param path the path to navigate to
   * @param direction the direction the page is moving
   */
  public navigateToPath(path: string, direction: NavigationEnum) {
    if (path) {
      if (direction === NavigationEnum.Forward) {
         this.navCtrl.navigateForward(path);
      }

      if (direction === NavigationEnum.Back) {
        this.navCtrl.navigateBack(path);
      }

      if (direction === NavigationEnum.Root) {
        this.navCtrl.navigateRoot(path);
      }
    }
  }

  /**
   * displays a component as a modal
   * @param component the component to raise in the modal
   * @param componentProps properties to pass to the component
   * modalController NEEDED FOR THIS TO ACTUALLY WORK LOL
   */
  public async presentModal(component, cssClass?: string, componentProps = {}, enableBackdropDismiss?: boolean) {
    this.modal = await this.modalController.create({
      component,
      componentProps,
      showBackdrop: true,
      backdropDismiss: enableBackdropDismiss,
      cssClass
    });

    return await this.modal.present();
  }

  /**
   * displays a component as a modal
   * @param component the component to raise in the modal
   * @param componentProps properties to pass to the component
   * modalController NEEDED FOR THIS TO ACTUALLY WORK LOL
   */
  public async presentModal2(component, cssClass?: string, componentProps = {}, enableBackdropDismiss?: boolean) {
    this.modal = await this.modalController.create({
      component,
      componentProps,
      showBackdrop: true,
      backdropDismiss: enableBackdropDismiss,
      cssClass
    });
    await this.modal.present();
    return this.modal;
  }

  /**
   * Get the modal
   */
  public getModal() {
    return this.modal;
  }

  /**
   * Close modal
   */
  public async closeModal() {
    this.modal.dismiss();
    this.modalDismiss.emit();
  }

  /**
   * displays a confirm modal
   * the 'Okay' emits an event via alertOk
   * subscribe to alertOk to perform necessary actions
   * 'Cancel' dismisses the modal
   * SHOULD GET MOVED SOMEWHERE ELSE(Like an alert service)
   * @param message message to display
   * @param header text to display in header
   */
  async presentAlertConfirm(
    message: string,
    header?: string,
    cancelButtonText?: string,
    okButtonText?: string
  ) {
    const alert = await this.alertController.create({
      header: header || 'Confirm!',
      message,
      cssClass: 'be-alert',
      buttons: [
        {
          text: cancelButtonText || 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: okButtonText || 'Okay',
          cssClass: 'secondary',
          handler: () => {
            this.alertOk.emit();
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * will display a single button alert
   * @param message the message to display
   * @param header the header title
   * @param okButtonText ok button text
   */
  async presentAlert(
    message: string,
    header?: string,
    okButtonText?: string
  ) {
    const alert = await this.alertController.create({
      header: header || 'Confirm!',
      message,
      cssClass: 'be-alert',
      buttons: [
        {
          text: okButtonText || 'Okay',
          cssClass: 'secondary',
          handler: () => {
            this.singleAlertOk.emit();
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * displays a toast
   * REQUIRES TOAST CONTROLLER
   * @param message message to display
   */
  async presentToast(message: string, cssClass?: string, duration?: number ) {
    const toast = await this.toastController.create({
      message,
      cssClass: cssClass || 'be-success',
      duration: duration || 3000
    });
    toast.present();
  }

  /** takes a timestamp and converts it to a date */
  public convertTimestampToDate(timeStamp: Date) {
    return timeStamp && timeStamp[this.SECONDS] ?
      new Date(timeStamp[this.SECONDS] * 1000) : new Date();
  }

  /** converts the timestamps to date the given object */
  public convertDataTimestamps(model: any[]) {
    const result = [];
    const modifiedDate = 'modified_date';

    model.forEach((data) => {
      if (data[modifiedDate]) {
        data[modifiedDate] = this.convertTimestampToDate(data[modifiedDate]);
      }
      
      result.push(data);
    });

    return result;
  }

  /**
   * randomize an array
   * @param array The array to randomize
   */
  public randomizeArray(array: any[]) {
    // Shuffle answers
    return _.shuffle(array);
  }

  /**
   * randomize an array
   * @param array The array to randomize
   * @returns a promise that resolves once the array is shuffled
   */
   public async randomizeArrayAsync(array: any[]) {
    // Shuffle answers
    return Promise.resolve(_.shuffle(array));
  }

  sleep(ms: number, callback?) {
    if(!callback){return new Promise(resolve => setTimeout(resolve, ms));}
    
    return new Promise((resolve, rej) => setTimeout(() => {
      callback();
      resolve(true);
    }, ms));
  }

  /**
   * !!!IMPORTANT MUST INCLUDE loadingCtrl IN CONSTRUCTOR!!!
   * THIS NEEDS TO BE A SERVICE OH NO
   * shows the loading thing
   * @returns i dont really know it just presents and returns that? could be a reference to the loading object
   */
  public async showLoading(msg?) {
    if(!!this.loadingConfig){
      console.log('Loading Config already exists on show')
      // throw new Error('Loading Config already exists on show')
    }
    if(msg) {
      this.loadingConfig = await this.loadingCtrl.create({
        message: msg,
        translucent: false
      });
    } else {
      this.loadingConfig = await this.loadingCtrl.create({
        message: 'Please wait...',
        translucent: false
      });
    }
    

    return this.loadingConfig.present();
  }

  /** hides loading screen */
  public async hidLoading() {
    if (!!this.loadingConfig) {
      await this.loadingConfig.dismiss();
      // this.loadingConfig = null;
    } else {
      const tries = 3;
      const sleepTimer = 1000;
      for ( let i = 0; i < tries; i++) {
        await this.sleep(sleepTimer);
        try {
          this.loadingConfig.dismiss();
        } catch {
          throw new Error('loading config didnt exist on hid')
        }
      }
    }
  }
  /**
   * Change String to Title Case
   * @param str The String
   */
  public toTitleCase(str) {
    return str.replace(/\w\S*/g, function(t) {
      return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    });
  }

  public firebaseDateToDate(inputDate) {
    return new Date(inputDate.seconds * 1000 + inputDate.nanoseconds/1000000);
  }

  /**
   * mixes two rgb arrays of colors
   * @param color1 {r,g,b} input #1 ARRAYS NOT OBJECTS
   * @param color2 {r,g,b} input #2
   * @param weight percentage closer to color1
   * @returns 
   */
  public pickHex(color1, color2, weight = 0.5) {
    var w1 = weight;
    var w2 = 1 - w1;
    var rgb = {r: Math.round(color1.r * w1 + color2.r * w2),
        g: Math.round(color1.g * w1 + color2.g * w2),
        b: Math.round(color1.b * w1 + color2.b * w2)};
    return rgb;
  }

  public componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  
  public rgbToHex(rgb: any) {
    return "#" + this.componentToHex(rgb.r) + this.componentToHex(rgb.g) + this.componentToHex(rgb.b);
  }

  public hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return{
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    };
  }

  public async waitForMaps(maps: any[]){
    return await Promise.all(maps);
  }

  public async waitForTruthyVar(callback, context){
    let truthy_var = callback(context);
    for(let i = 0; i < 5; i++){
      if(truthy_var){
        return truthy_var;
      }
      await this.sleep(1000);
      truthy_var = callback(context);
    }
    if(!truthy_var){
      throw new Error(`truthy_var doesnt exist`)
    }
  }

}

