import { OutsideActivity } from "./outside_activity.model";

export class OutsideTraining {
    id: string;
    bonus_points: number;
    user_id: string;
    activities: OutsideActivity[];
    created_date: string;

    constructor(activities, bonus_points, user_id) {
        this.activities = activities;
        this.bonus_points = bonus_points;
        this.user_id = user_id;
    }
}
